import moment from "moment";
import { monthListString, yearList } from "../employeeService"
import validation from "../validation";
import PaySlipThumbnail from "../../components/reports/PaySlipThumbnail.vue";
export default {
    components: { PaySlipThumbnail },
    data: () => ({
		...validation,
		employees: [],
		totalPages: 0,
		year: null,
		month: null,
		isloading: false,
		valid: false,
		isConvertedEmployees: [],
		paySlipForm: new Form({
			endYearMonth: "",
			startYearMonth: "",
			employeeId: "",
		}),
		startMenu: false,
		endMenu: false,
		search: "",
		isLoading: false,
	}),
	computed: {
		currentUser() {
			return this.$store.state.auth.currentUser;
		},
		currentYearMonth() {
			return `${this.currentYear}-${this.currentMonth}`;
		},
		currentYear() {
			return new Date().getFullYear();
		},

		currentMonth() {
			return ("0" + (new Date().getMonth() + 1)).slice(-2);
		},
		startMonth: {
			get() {
				return monthListString();
			},
			set(val) {
				return val;
			},
		},
		startYear() {
			return yearList();
		},
		endMonth() {
			return monthListString();
		},
		endYear() {
			return yearList();
		},
},
async created() {
    await this.initialize();
},
methods: {
    async initialize() {
        this.isloading = true;
        await this.getPreviousEightMonths();
        await this.$store.dispatch("employees/fetchEmployeesIdsNames");
        this.isloading = false;
    },
    async getPreviousEightMonths() {
        let startDate = moment(new Date())
            .subtract(8, "month")
            .format("YYYY-MM");
        let endDate = this.currentYearMonth;
        const data = {
            employeeId: this.employeeIdsNames[0]?.employeeId,
            startYearMonth: startDate,
            endYearMonth: endDate,
        };
        this.paySlipForm = data;
        await this.$store.dispatch(`${this.$data.sourcePath}/${this.$data.actionType}`, data);
    },

    async findEmployeePayroll() {
        this.isLoading = true;
        await this.$store.dispatch(
            `${this.$data.sourcePath}/${this.$data.actionType}`,
            this.paySlipForm
        );
        this.isLoading = false;
    },
},
}